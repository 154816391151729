import nprogress from 'nprogress'
import '/@/styles/nprogress.css'

nprogress.configure({
  template: `
<div class="bar" role="bar">
  <div class="peg"></div>
</div>
<div class="spinner-wrapper">
  <div class="spinner-mask"></div>
  <div class="spinner" role="spinner">
    <div class="box"></div>
    <div class="box-b"></div>
    <div class="box-a"></div>
  </div>
</div>
`,
})

nprogress.start()

import('./index')
